export const CropConstants = {
  SUNFLOWER_CROP_ID: 2,
  CORN_CROP_ID: 1,
  SILAGE_CORN_CROP_ID: 3,
  SUMMER_CORN_CROP_ID: 6,
  BARLEY_CROP_ID: 4,
  CORN_SMALLCASE: 'corn',
  WINTER_OILSEED_RAPE: 'Winter Oilseed Rape',
  HYBRID_BARLEY: 'Hybrid Barley',
  WOSR: 'WOSR',
  BARLEY: 'barley',
  SUMMER_CORN_SMALLCASE: 'summer corn',
  SUNFLOWER_SMALLCASE: 'sunflower',
  SILAGE_CORN_SMALLCASE: 'silage corn',
  NO_TILLAGE: 'No Tillage',
  MINIMAL_TILLAGE: 'Minimal Tillage',
  SUNFLOWER_BAG_CAPACITY: 150000,
  CORN_BAG_CAPACITY: 50000,
  GRAIN_CORN: 'grain corn',
  POLAND_CODE: 'POL',
  POLAND: 'POLAND',
  ROMANIA: 'ROMANIA',
  ROMANIA_CODE: 'RO',
  ROMANIA_POSTAL_CODE: 'ROU',
  HUNGARY_CODE: 'HU',
  UKRAINE_CODE: 'UKR',
  BULGARIA_CODE: 'BGR',
  BRAZIL_CODE: 'BRZ',
  SOUTH_AFRICA_CODE: 'ZAF',
  WHEAT_SMALLCASE: 'wheat',
  SOUTH_AFRICA: 'South Africa',
  Brazil: 'Brazil',
  Bulgaria: 'Bulgaria',
  BULGARIA_ISO2: 'BG',
};

// List of crops needs to excluded like Winter Oilseed Rape crop
export const ExcludedCropList = [CropConstants.WINTER_OILSEED_RAPE];

const harvestDateConstants = [
  {
    countryCode: 'POL',
    date: '1 Oct',
  },
  {
    countryCode: 'ROU',
    date: '1 Sep',
  },
  {
    countryCode: 'HUN',
    date: '1 Oct',
  },
];
export const sunflowerEnabledCountries = ['ROU', 'HUN'];

export const getHarvestDate = (countryCode: string | undefined): string => {
  return (
    harvestDateConstants.find((data) => data.countryCode === countryCode)?.date ??
    harvestDateConstants[0].date
  );
};

export const countriesWithCornCrop = [
  CropConstants.POLAND_CODE,
  CropConstants.BULGARIA_CODE,
  CropConstants.UKRAINE_CODE,
  CropConstants.ROMANIA_POSTAL_CODE,
];

export const tppZoneInSouthAfrica = 'Free State,North West and KwaZulu-Natal-';

export const southAfricaCropwiseMailId = 'cropwise.za@syngenta.com';

export const growthStageCrops = ['silage corn', 'summer corn', 'corn', 'sunflower'];
