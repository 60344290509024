import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './GrowthStages.css';
import GrowthStageCard from './GrowthStageCard';
import { useBreakpoint } from 'hooks';
import {
  AbioticStressColorCodeSection,
  DataInputSection,
  FooterSection,
  GraphSection,
  GrowthStageBar,
  GrowthStageColorCodeSection,
  MonthLabel,
  MonthLabelContainer,
  PlantingDateSection,
  PlantingIcon,
  ProductLabel,
  SectionHeading,
  StagesContainer,
  StagesText,
  SubHeadingSection,
  TimelineContainer,
  PhenologyIcon,
  AbioticGraphContainer,
  GrowthStageTimeline,
} from './GrowthStage.styles';
import moment, { Moment } from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { colors, Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { GrowthVarietyStages } from 'base/types/growthStage';
import TimelineIcon from 'assets/icons/calendar_plan.svg';
import AbioticGraph from './AbioticGraph';
import { AbioticStress, AbioticStressData } from 'base/types/AbioticStress';
import {
  SkeletonMobileBottomContainer,
  SkeletonMobileContainer,
  SkeletonMobileTopContainer,
  SkeletonRowStyled,
  SkeletonTitleStyled,
} from '../SoilCharacteristics.styles';
import { useAppState } from 'context/AppState';
import { isDateBetweenMonthRange } from 'utils/dateValidation';
import AbioticStressSection from './AbioticStressSection';
import { StressType } from 'context/store/flowReducer';

const IconPlanting = React.lazy(() => import('components/Icons/IconPlanting'));
const IconLeaveEmergence = React.lazy(() => import('components/Icons/IconLeaveEmergence'));
const IconWetLeaf = React.lazy(() => import('components/Icons/IconWetLeaf'));
const IconCropCycle = React.lazy(() => import('components/Icons/IconCropCycle'));
const IconMilkStage = React.lazy(() => import('components/Icons/IconMilkStage'));
import Error from 'assets/icons/icon-error-exclamation.svg';
import { Product } from 'base/types/RecommendationCropwise';
import track from 'utils/amplitudeWrapper';
import { CropConstants } from 'utils/constants/Crop';

interface Stage {
  name: string;
  startDate: string | Date;
  endDate?: Date;
  type: string;
  deviation: number;
}

interface TimelineProps {
  products: GrowthVarietyStages[];
  startDate: Date;
  endDate: Date;
  cropName: string;
  stressLevel: AbioticStressData;
  onPlantingDateChange: (date: Date) => void;
  productList?: Product[];
  onCalenderChange: (calenderOpen: boolean) => void;
}

interface GrowthStageColorParam {
  cropName: string;
}

const AbioticStressColorCode = () => {
  const { t } = useTranslation();

  return (
    <AbioticStressColorCodeSection>
      <div>
        <h3>{t('Heat Stress')}:</h3>
        <span id="lowHeatStress">{t('Low')}</span>
        <span id="medHeatStress">{t('Med')}</span>
        <span id="highHeatStress">{t('High')}</span>
      </div>
      <div>
        <h3>{t('Cold Stress')}:</h3>
        <span id="lowColdStress">{t('Low')}</span>
        <span id="medColdStress">{t('Med')}</span>
        <span id="highColdStress">{t('High')}</span>
      </div>
    </AbioticStressColorCodeSection>
  );
};
const GrowthStageColorCode = ({ cropName }: GrowthStageColorParam) => {
  const { t } = useTranslation();
  return (
    <GrowthStageColorCodeSection>
      <div id="plantingColor">
        <IconPlanting />
        <span>{t('Planting')}</span>
      </div>
      {cropName === CropConstants.SUNFLOWER_SMALLCASE && (
        <div id="leaveEmergenceColor">
          <IconLeaveEmergence color={colors.neutral60} />
          <span>{t('Leave Emergence')}</span>
        </div>
      )}
      <div id="floweringColor">
        <IconWetLeaf color={colors.neutral60} />
        <span>
          {t(cropName === CropConstants.SUNFLOWER_SMALLCASE ? t('Flowering') : t('Silking'))}
        </span>
      </div>
      {cropName === CropConstants.SILAGE_CORN_SMALLCASE && (
        <div id="floweringColor">
          <IconMilkStage color={colors.neutral60} />
          <span>{t('Milk Stage')}</span>
        </div>
      )}
      <div id="maturityColor">
        <IconCropCycle color={colors.neutral60} />
        <span>{t('Maturity')}</span>
      </div>
    </GrowthStageColorCodeSection>
  );
};

const GrowthStages: React.FC<TimelineProps> = ({
  products,
  startDate,
  endDate,
  cropName,
  stressLevel,
  onPlantingDateChange,
  productList,
  onCalenderChange,
}) => {
  const [monthWidth, setMonthWidth] = useState(20); // Default width
  const [weekWidth, setWeekWidth] = useState(4); // Default width
  const [plantingDate, setPlantingDate] = useState(startDate);
  const [harvestDate, setHarvestDate] = useState(endDate);
  const [numberOfMonths, setNumberOfMonths] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [overlapCount, setOverlapCount] = useState(0);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let oldValue = 0;
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation();
  const {
    apiData: { growthStageLoading, abioticStageLoading, growthStageError },
    flow: { abioticStageStressType },
  } = useAppState();

  const isEmptyMonthRequired = useMemo(() => {
    return plantingDate.getDate() < 11;
  }, [plantingDate]);

  useEffect(() => {
    const totalMonths =
      // (endDate.getFullYear() - plantingDate?.getFullYear()) * 12 +
      harvestDate.getMonth() - plantingDate?.getMonth() + 1;
    setNumberOfMonths(totalMonths);
    const calculateMonthWidth = () => {
      const availableWidth = isMobile ? window.innerWidth - 32 : window.innerWidth - 128; // Subtracting pixels of padding
      const timeLineTextWidth = document.getElementById('timeLineText')?.clientWidth ?? 0;
      let widthForMonths = availableWidth - timeLineTextWidth;
      if (isEmptyMonthRequired) {
        const emptyMonthBoxWidth = document.getElementById('emptyMonthBox')?.clientWidth ?? 0;
        widthForMonths -= emptyMonthBoxWidth; // Subtracting width of empty month
      }
      const monthWidthInPercentage = (widthForMonths / totalMonths / availableWidth) * 100;
      setMonthWidth(Number(monthWidthInPercentage.toFixed(3)));
      const weekWidthInPercentage = (widthForMonths / (totalMonths * 4.289) / availableWidth) * 100;
      setWeekWidth(Number(weekWidthInPercentage.toFixed(3)));
    };
    calculateMonthWidth();
  }, [plantingDate, isMobile, isEmptyMonthRequired, growthStageLoading]);

  const getPositionForDate = (date: Date, isLastStage: boolean): number => {
    const monthsDiff =
      (date.getFullYear() - plantingDate.getFullYear()) * 12 +
      date.getMonth() -
      plantingDate.getMonth();
    const dayPosition = (date.getDate() - 1) / 30; // Approximating month length to 30 days
    let positionFromLeft = Number(((monthsDiff + dayPosition) * monthWidth).toFixed(3));
    if (isLastStage && harvestDate.getMonth() === date.getMonth() && date.getDate() >= 28) {
      positionFromLeft--;
    }
    return isEmptyMonthRequired && !isMobile ? positionFromLeft + 7 : positionFromLeft;
  };
  const getPositionForStressLine = (date: Date, index: number): { left: number; width: number } => {
    const position = {
      left: 0,
      width: abioticStageStressType === StressType.Intensity ? weekWidth / 7 : weekWidth,
    };
    const monthsDiff = date.getMonth() - plantingDate.getMonth();
    if (monthsDiff < 0) {
      position.left = -5;
      return position;
    }
    const dayPosition = (date.getDate() - 1) / 7;
    let positionFromLeft = Number(((monthsDiff * 4.289 + dayPosition) * weekWidth).toFixed(3));
    if (isMobile) {
      position.left = positionFromLeft + 9;
      return position;
    }
    // Added condition to check if there is overlapping b/w two consecutive stress lines for Frequency type,
    // then adjust the width & left position of the stress line to avoid overlapping.
    if (
      index > 0 &&
      abioticStageStressType === StressType.Frequency &&
      positionFromLeft < oldValue
    ) {
      const val = Number((oldValue - positionFromLeft).toFixed(3));
      positionFromLeft += val;
      position.width = weekWidth - val;
    }
    oldValue = positionFromLeft + position.width;
    position.left = isEmptyMonthRequired ? positionFromLeft + 22 : positionFromLeft + 15;
    return position;
  };

  const checkCardOverlappedWithStress = (
    cardDate: Date
  ): { isOverlapping: boolean; stressValue?: number; type?: string } => {
    const cardYear = cardDate.getFullYear();
    const extraDaysThreshold = abioticStageStressType === StressType.Frequency ? 7 : 0;
    const checkOverlap = (stressArray: AbioticStress[], cardDate: Date, cardYear: number) => {
      for (const stress of stressArray) {
        const stressDate = new Date(stress.date);
        stressDate.setFullYear(cardYear);
        if (
          stressDate <= cardDate &&
          new Date(stressDate.setDate(stressDate.getDate() + extraDaysThreshold)) >= cardDate
        ) {
          return {
            stressValue: stress.stressValue,
            type: stress.type,
          };
        }
      }
      return null;
    };
    const heatStressObj = checkOverlap(stressLevel.heat_risk, cardDate, cardYear);
    if (heatStressObj) {
      return {
        isOverlapping: true,
        ...heatStressObj,
      };
    }
    const coldStressObj = checkOverlap(stressLevel.frost_risk, cardDate, cardYear);
    if (coldStressObj) {
      return {
        isOverlapping: true,
        ...coldStressObj,
      };
    }
    return { isOverlapping: false };
  };

  /*const isWithinSameWeek = (referenceDate: Date, stressDate: Date, isStart: boolean): boolean => {
    let dateDiff = stressDate.getDate() - referenceDate.getDate();
    if (!isStart) {
      dateDiff = 25 - stressDate.getDate();
    }
    return dateDiff < 7 && dateDiff >= 0 && stressDate.getMonth() === referenceDate.getMonth();
  };*/

  /* const hasSignificantGap = (position1: number, position2: number, threshold: number): boolean => {
    return position1 - position2 > threshold;
  }; */

  const checkIfDottedLineRequired = ({
    stressIndex,
    type,
    isAtStart,
  }: {
    stressIndex: number;
    type: string;
    isAtStart: boolean;
  }): boolean => {
    /*const stressData = stressLevel[type as keyof AbioticStressData];
    const stressDate = new Date(stressData[stressIndex].date);
    const currentStressLinePosition = getPositionForStressLine(stressDate);

    if (currentStressLinePosition <= 0) {
      return false;
    }

    if (isAtStart) {
      if (stressIndex === 0) {
        return true;
      }

      const previousStressDate = new Date(stressData[stressIndex - 1].date);
      const previousStressLinePosition = getPositionForStressLine(previousStressDate);

      if (
        isWithinSameWeek(plantingDate, stressDate, isAtStart) ||
        hasSignificantGap(currentStressLinePosition, previousStressLinePosition + weekWidth, 0.2)
      ) {
        return true;
      }
    } else {
      if (stressIndex === stressData.length - 1) {
        return true;
      }

      const nextStressDate = new Date(stressData[stressIndex + 1].date);
      const nextStressLinePosition = getPositionForStressLine(nextStressDate);

      if (
        isWithinSameWeek(harvestDate, stressDate, isAtStart) ||
        hasSignificantGap(nextStressLinePosition, currentStressLinePosition + weekWidth, 0.2)
      ) {
        return true;
      }
    }*/

    return false;
  };

  const checkStressDateInRange = (date: Date): boolean => {
    const plantingYear = plantingDate.getFullYear();
    date.setFullYear(plantingYear);
    if (
      date >= plantingDate &&
      date < harvestDate &&
      (date.getMonth() < harvestDate.getMonth() || date.getDate() <= 25)
    ) {
      return true;
    }
    return false;
  };

  const getMonthLabels = useCallback(() => {
    const labels = [];
    let currentMonth = new Date(plantingDate).getMonth();
    const leftWhenNotMobile = isEmptyMonthRequired ? 6.999 : 0;
    let left = !isMobile ? leftWhenNotMobile : 0;
    while (currentMonth <= harvestDate.getMonth()) {
      labels.push({
        month: months[currentMonth],
        left: left,
        width: monthWidth,
      });
      left += monthWidth;
      currentMonth++;
    }
    return labels;
  }, [monthWidth, plantingDate]);

  const handlePlantDateChange = (plantingDate: Moment | null) => {
    if (plantingDate) {
      setPlantingDate(plantingDate.toDate());
      const year = plantingDate.year();
      setHarvestDate(new Date(year, harvestDate.getMonth(), harvestDate.getDate()));
      onPlantingDateChange(plantingDate?.toDate());
      track('Growth Stage planting date interaction', {
        'Date selected': plantingDate.format('YYYY-MM-DD'),
      });
    }
  };
  const handleRetryClick = () => {
    onPlantingDateChange(plantingDate);
    track('Growth Stage retry button clicked', { 'retry button clicked': true });
  };

  const calculateContainerWidth = ({ isPlantingIcon }: { isPlantingIcon: boolean }): string => {
    const plantingDateNumber = plantingDate?.getDate() ?? 0;
    const width = (plantingDateNumber / 30) * monthWidth;

    const calculateWidthForPlantingIcon = () => `${width}%`;

    const calculateWidthForNonPlantingIcon = () => (isMobile ? `${91 - width}%` : `${85 - width}%`);

    return isPlantingIcon ? calculateWidthForPlantingIcon() : calculateWidthForNonPlantingIcon();
  };

  const canDisableCalendar = (current: any): boolean => {
    const maxOldRecommendationDateAllowed = new Date();
    maxOldRecommendationDateAllowed.setDate(maxOldRecommendationDateAllowed.getDate() - 305);
    return (
      moment(current).isBefore(maxOldRecommendationDateAllowed) ||
      isDateBetweenMonthRange(current, 8, 1)
    );
  };

  const SkeletonLoader = useMemo(() => {
    if (isMobile) {
      return (
        <SkeletonMobileContainer>
          <SkeletonMobileTopContainer>
            <SkeletonTitleStyled width="80%" active size="large" />
          </SkeletonMobileTopContainer>
          <SkeletonMobileBottomContainer>
            <SkeletonRowStyled active size="large" />
            <SkeletonRowStyled active size="large" />
          </SkeletonMobileBottomContainer>
        </SkeletonMobileContainer>
      );
    }
    return <Skeleton active paragraph={{ rows: 11, width: '100%' }} />;
  }, [isMobile]);

  const checkStageOverlap = useCallback(
    (currentProduct: GrowthVarietyStages, currentStage: Stage, currentIndex: number) => {
      let isOverlapping = false;

      const getOverlapThreshold = () => {
        if (screenWidth < 480) {
          return 10;
        } else if (screenWidth < 768) {
          return 11;
        } else if (screenWidth < 1024) {
          return 8;
        } else {
          return 5;
        }
      };
      const overlapThreshold = getOverlapThreshold();
      if (currentProduct?.stages) {
        const currentLeft = getPositionForDate(
          new Date(currentStage.startDate),
          currentIndex === currentProduct.stages.length - 1
        );
        // Checking if previous stage card is coming close to the current stage card
        // and if it's coming closer than marked the current card as overlapped.
        const otherStage = currentProduct?.stages[currentIndex - 1];
        if (otherStage) {
          const otherLeft = getPositionForDate(new Date(otherStage.startDate), false);
          const diff = Math.abs(currentLeft - otherLeft);
          if (diff < overlapThreshold) {
            isOverlapping = true;
          }
        }
      }

      return { isOverlapping };
    },
    [getPositionForDate, screenWidth]
  );

  const prepareProductData = useCallback(
    (product: GrowthVarietyStages) => {
      let productIsOverlapping = false;
      const updatedStages = product?.stages?.map((stage, index) => {
        const { isOverlapping } = checkStageOverlap(product, stage, index);
        if (isOverlapping) {
          productIsOverlapping = true;
        }
        return {
          ...stage,
          isOverlapping,
        };
      });

      return {
        ...product,
        stages: updatedStages,
        isOverlapping: productIsOverlapping,
      };
    },
    [checkStageOverlap, screenWidth]
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    const throttle = (func: any, wait: number) => {
      let flag = true;
      return function () {
        if (flag) {
          flag = false;
          setTimeout(() => {
            flag = true;
          }, wait);
          func();
        }
      };
    };
    const handleResizeThrottled = throttle(handleResize, 1000);
    window.addEventListener('resize', handleResizeThrottled);
    return () => {
      window.removeEventListener('resize', handleResizeThrottled);
    };
  }, []);

  const productsWithOverlapData = useMemo(() => {
    return products.map((product) => prepareProductData(product));
  }, [products, prepareProductData, screenWidth]);

  useEffect(() => {
    const count = productsWithOverlapData.filter((product) => product.isOverlapping).length;
    setOverlapCount(count);
  }, [productsWithOverlapData]);

  function getCommercialName(productName: string) {
    const product = productList?.find((item) => item.product_name === productName);
    return product ? product.commercial_name : productName;
  }

  const handleOpenChange = (status: boolean) => {
    onCalenderChange(status);
  };

  return (
    <>
      <TimelineContainer data-testid="growth-stage">
        <SectionHeading>{t('Management and Planting Advice')}</SectionHeading>
        <SubHeadingSection>
          <h3>{t('Phenological Growth Stages')}</h3>
          <p>
            {t(
              'Below are predictive growth and reproductive stages for recommended products and environmental conditions that might affect them.'
            )}
          </p>
        </SubHeadingSection>
        {
          <>
            <DataInputSection data-testid="dssat-input-section">
              <PlantingDateSection data-testid="planting-date-section">
                <label>{t('Planting date')}</label>
                <DatePicker
                  disabled={growthStageLoading || abioticStageLoading}
                  disabledDate={(currentDate) => canDisableCalendar(currentDate)}
                  data-testid="planting-date-input"
                  value={moment(plantingDate)}
                  defaultValue={moment(plantingDate)}
                  onChange={handlePlantDateChange}
                  allowClear={false}
                  onOpenChange={handleOpenChange}
                />
                <span>{t('Specify your target planting date.')}</span>
              </PlantingDateSection>
              <AbioticStressSection />
            </DataInputSection>
            {growthStageLoading ? (
              SkeletonLoader
            ) : (
              <>
                {growthStageError ? (
                  <div className="error-bar">
                    <div className="error-message">
                      <img src={Error} alt="error-icon" className="icon-error" />
                      {t(
                        'An error occured while loading growth stages for the selected planting date'
                      )}
                    </div>
                    <button className="retry-text" onClick={handleRetryClick}>
                      {t('Retry')}
                    </button>
                  </div>
                ) : (
                  <>
                    {isMobile && <GrowthStageColorCode cropName={cropName} />}
                    <GraphSection>
                      <MonthLabelContainer>
                        <MonthLabel
                          isDefault={true}
                          left={0}
                          width={15}
                          className="timeline-text"
                          id="timeLineText"
                        >
                          {isMobile ? (
                            <img src={TimelineIcon} alt="Timeline" height="16px" width="16px" />
                          ) : (
                            <>
                              <img src={TimelineIcon} alt="Timeline" height="13px" />
                              {t('Timeline')}
                            </>
                          )}
                        </MonthLabel>
                        {isEmptyMonthRequired && !isMobile && (
                          <MonthLabel isDefault={false} left={0} width={7} id="emptyMonthBox" />
                        )}
                        {getMonthLabels().map((label, index) => (
                          <MonthLabel
                            key={label.month}
                            isDefault={false}
                            left={label.left}
                            width={label.width}
                            isLastMonth={numberOfMonths === index + 1}
                          >
                            {t(`${label.month}`)}
                          </MonthLabel>
                        ))}
                      </MonthLabelContainer>
                      <StagesContainer>
                        {isMobile ? (
                          <>
                            <PhenologyIcon>
                              <IconLeaveEmergence
                                color={colors.neutral60}
                                height="16px"
                                width="16px"
                              />
                            </PhenologyIcon>
                            <PlantingIcon
                              width={
                                isEmptyMonthRequired
                                  ? '5%'
                                  : calculateContainerWidth({ isPlantingIcon: true })
                              }
                            >
                              <IconPlanting />
                            </PlantingIcon>{' '}
                          </>
                        ) : (
                          <>
                            <StagesText>
                              <IconLeaveEmergence
                                color={colors.neutral60}
                                height="13px"
                                width="13px"
                              />
                              <span>{t('Stages')}</span>
                            </StagesText>
                            <PlantingIcon
                              width={
                                isEmptyMonthRequired
                                  ? '7%'
                                  : calculateContainerWidth({ isPlantingIcon: true })
                              }
                            >
                              <IconPlanting />
                            </PlantingIcon>
                          </>
                        )}
                        <GrowthStageBar
                          className="color-container"
                          width={
                            isEmptyMonthRequired
                              ? '78%'
                              : calculateContainerWidth({ isPlantingIcon: false })
                          }
                          isEmptyMonthRequired={isEmptyMonthRequired}
                        >
                          <span>{t('Vegetative')}</span>
                          <span>{t('Reproductive')}</span>
                        </GrowthStageBar>
                      </StagesContainer>
                      <div className="timeline-row-container">
                        {productsWithOverlapData?.map((product, productIndex) => (
                          <div className="timeline-row-data" key={product.name}>
                            <React.Fragment key={product.name}>
                              <GrowthStageTimeline isOverLap={product.isOverlapping}>
                                <ProductLabel>{getCommercialName(product.name)}</ProductLabel>
                                {product?.stages?.map((stage, index) => (
                                  <GrowthStageCard
                                    data-testid="growth-stage-card"
                                    key={stage.name}
                                    deviation={stage.deviation}
                                    stageName={stage.name}
                                    type={stage.type}
                                    date={new Date(stage.startDate).toISOString().split('T')[0]}
                                    cropName={cropName}
                                    monthWidth={monthWidth}
                                    top={(productIndex + 1) * 102}
                                    left={getPositionForDate(
                                      new Date(stage.startDate),
                                      (product?.stages?.length ?? 0) - 1 === index
                                    )}
                                    isLastDate={
                                      new Date(stage.startDate).getMonth() ===
                                        harvestDate.getMonth() &&
                                      new Date(stage.startDate).getDate() + stage.deviation >= 30
                                    }
                                    hasOverlappedWithStress={checkCardOverlappedWithStress(
                                      new Date(stage.startDate)
                                    )}
                                    isOverlapping={stage.isOverlapping}
                                    isOverLappingForProduct={product.isOverlapping}
                                  />
                                ))}
                              </GrowthStageTimeline>
                            </React.Fragment>
                          </div>
                        ))}
                        <AbioticGraphContainer
                          graphHeight={
                            Math.abs(products.length - overlapCount) * 104 +
                            Math.abs(products.length - 1) * 3 +
                            overlapCount * 155
                          }
                        >
                          {!abioticStageLoading &&
                            stressLevel.frost_risk.map(
                              (fStress: AbioticStress, fStressIndex: number) => (
                                <AbioticGraph
                                  key={fStress.date}
                                  positionData={getPositionForStressLine(
                                    new Date(fStress.date),
                                    fStressIndex
                                  )}
                                  stressValue={fStress.stressValue}
                                  type={fStress.type}
                                  hasDottedLineAtStart={checkIfDottedLineRequired({
                                    stressIndex: fStressIndex,
                                    type: 'frost_risk',
                                    isAtStart: true,
                                  })}
                                  hasDottedLineAtEnd={checkIfDottedLineRequired({
                                    stressIndex: fStressIndex,
                                    type: 'frost_risk',
                                    isAtStart: false,
                                  })}
                                  isVisible={checkStressDateInRange(new Date(fStress.date))}
                                />
                              )
                            )}
                          {!abioticStageLoading &&
                            stressLevel.heat_risk.map(
                              (hStress: AbioticStress, hStressIndex: number) => (
                                <AbioticGraph
                                  key={hStress.date}
                                  positionData={getPositionForStressLine(
                                    new Date(hStress.date),
                                    hStressIndex
                                  )}
                                  stressValue={hStress.stressValue}
                                  type={hStress.type}
                                  hasDottedLineAtStart={checkIfDottedLineRequired({
                                    stressIndex: hStressIndex,
                                    type: 'heat_risk',
                                    isAtStart: true,
                                  })}
                                  hasDottedLineAtEnd={checkIfDottedLineRequired({
                                    stressIndex: hStressIndex,
                                    type: 'heat_risk',
                                    isAtStart: false,
                                  })}
                                  isVisible={checkStressDateInRange(new Date(hStress.date))}
                                />
                              )
                            )}
                        </AbioticGraphContainer>
                      </div>
                    </GraphSection>
                    {!isMobile && (
                      <FooterSection>
                        <AbioticStressColorCode />
                        <GrowthStageColorCode cropName={cropName} />
                      </FooterSection>
                    )}
                  </>
                )}
              </>
            )}
          </>
        }
      </TimelineContainer>
      {isMobile && (
        <FooterSection>
          <AbioticStressColorCode />
        </FooterSection>
      )}
    </>
  );
};

export default GrowthStages;
