import { useTranslation } from 'react-i18next';
import {
  RecommendationInfoSection,
  RecommendationInfoWrapper,
  RecommendationInfoTitle,
  RecommendationInfoUserName,
  RecommendationInfoDate,
  RecommendationInfoFieldInfo,
  RecommendationInfoReportOverview,
  StyledHeaderText,
  StyledHeaderSmallText,
  IconBox,
  StyledHeaderVerySmallText,
  RecommendationInfoFieldInfoMobilePrimary,
  RecommendationInfoFieldInfoMobileSecondary,
  RecommendationInfoFieldInfoContainer,
  RecommendationInfoTitleNonDDG,
  RecommendationInfoUserDetailsNonDDG,
} from './RecommendationInfo.styles';
import { useAppState } from 'context/AppState';
import Calendar from 'assets/icons/calendar_today.svg';
import TopBarPattern from 'assets/icons/topbar_pattern.svg';
import Field from 'assets/icons/field.svg';
import Separator from 'assets/icons/divider_vertical.svg';
import Corn from 'assets/icons/corn.svg';
import Barley from 'assets/icons/barley_crop.svg';
import Wheat from 'assets/icons/wheat_crop.svg';
import Sunflower from 'assets/icons/sunflower_crop.svg';
import Soyabean from 'assets/icons/soyabean_crop.svg';
import Msg from 'assets/icons/msg.svg';
import Phone from 'assets/icons/local_phone.svg';
import Mail from 'assets/icons/mail.svg';
import { getTrialLocation } from 'utils/trialLocationData';
import { useMemo } from 'react';
import { Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { useBreakpoint } from 'hooks';
import { CropConstants } from 'utils/constants/Crop';

interface RecInfoProps {
  isRecommendationAPILoading: boolean;
  isNonDDG: boolean;
}
const getCropIcon = (cropName: string | undefined) => {
  switch (cropName) {
    case 'corn':
    case 'silage corn':
    case 'summer corn':
      return Corn;
    case 'sunflower':
      return Sunflower;
    case 'barley':
      return Barley;
    case 'wheat':
      return Wheat;
    case 'soybeans':
      return Soyabean;
    default:
      return Corn;
  }
};
const RecommendationInfo = (props: RecInfoProps): JSX.Element => {
  const { isRecommendationAPILoading, isNonDDG } = props;
  const { isMobile } = useBreakpoint();
  const [t, i18n] = useTranslation();
  const {
    apiData: {
      recommendation,
      recommendationCropwise,
      nonDDGUserDetails,
      nonDDGUserDetailsLoading,
    },
  } = useAppState();
  const fieldSummaryArea = recommendationCropwise ? recommendationCropwise.summary_area : '';
  const date = recommendationCropwise && new Date(recommendationCropwise.created_on);
  const recommendationCW = recommendationCropwise?.recommendations[0];
  const userName = recommendation?.userContactFromAudit;
  const countryRegion =
    recommendationCW?.multi_field?.config?.agronomicInputs?.extraInfo?.countryRegion;
  const formattedDate = date?.toLocaleString(i18n.language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const SkeletonLoader = useMemo(() => {
    return (
      <>
        <Skeleton
          active
          style={{ padding: '100px 64px 0px 64px' }}
          paragraph={{ rows: 4, width: '100%' }}
        />
      </>
    );
  }, []);

  const getNonDDGUserDetails = () => {
    return (
      <>
        <RecommendationInfoTitleNonDDG>
          {recommendationCropwise?.organization_name}
        </RecommendationInfoTitleNonDDG>
        <RecommendationInfoDate>
          <img src={Calendar} alt="No img found" style={{ marginRight: '8px' }} />
          {`${t('Created')}: `}&nbsp;<b>{formattedDate}</b>
        </RecommendationInfoDate>
        <RecommendationInfoUserDetailsNonDDG
          isMobile={isMobile}
          data-testid="non-ddg-user-contact-details"
        >
          <div style={{ textAlign: 'left' }}>
            {`${t('By')}: ${nonDDGUserDetails?.first_name || ''} ${
              nonDDGUserDetails?.last_name || ''
            }`}
            {!isMobile && <img src={Separator} alt="No img found" className="separator-icon" />}
          </div>
          <div className={isMobile ? 'user-details-mobile' : 'user-details-desktop'}>
            {!!nonDDGUserDetails?.phone && (
              <div className={isMobile ? 'contact-details-mobile' : 'contact-details-desktop'}>
                <img src={Phone} alt="No img found" className="icon-padding" />
                <div className={isMobile ? 'user-details-text' : ''}>
                  <a className="text-link" href={`tel:+${nonDDGUserDetails?.phone}`}>
                    {nonDDGUserDetails?.phone}
                  </a>
                </div>
                <img src={Separator} alt="No img found" className="separator-icon" />
              </div>
            )}
            <div className={isMobile ? 'contact-details-mobile' : 'contact-details-desktop'}>
              <img src={Mail} alt="No img found" className="icon-padding" />
              <div className={isMobile ? 'user-details-text' : ''}>
                <a className="text-link" href={`mailto:${nonDDGUserDetails?.email}`}>
                  {nonDDGUserDetails?.email}
                </a>
              </div>
            </div>
          </div>
        </RecommendationInfoUserDetailsNonDDG>
      </>
    );
  };

  const getDDGUserDetails = () => {
    return (
      <>
        <RecommendationInfoTitle>{`${t('CREATED FOR')}:`}</RecommendationInfoTitle>
        <RecommendationInfoUserName data-testid="user-name">
          {recommendation && `${userName?.firstName} ${userName?.lastName}`}
        </RecommendationInfoUserName>
        <RecommendationInfoDate>
          <img src={Calendar} alt="No img found" style={{ marginRight: '8px' }} />
          {`${t('Created')}: `}&nbsp;<b>{formattedDate}</b>
        </RecommendationInfoDate>
      </>
    );
  };

  const totalFields = recommendationCW?.multi_field?.assignments.length;

  const getFieldDetailsMobile = () => {
    return (
      <RecommendationInfoFieldInfoContainer>
        <RecommendationInfoFieldInfoMobilePrimary data-testid="rec-field-infomobile">
          <img src={Field} alt="No img found" className="icon-size field-image" />
          {totalFields && totalFields > 1
            ? `${totalFields} ${t('fields')}`
            : `${totalFields} ${t('field')}`}
          <div className="white-dot" />
          {`${parseFloat(fieldSummaryArea).toFixed(2)} ${t('ha')}`}
        </RecommendationInfoFieldInfoMobilePrimary>
        <RecommendationInfoFieldInfoMobileSecondary>
          <img
            src={getCropIcon(recommendationCW?.crop_name.toLowerCase())}
            alt="No img found"
            className="icon-size crop-icon"
          />
          {t(
            `${recommendationCW?.crop_type
              .substring(0, 1)
              .toUpperCase()}${recommendationCW?.crop_type.substring(1)}`
          )}
        </RecommendationInfoFieldInfoMobileSecondary>
      </RecommendationInfoFieldInfoContainer>
    );
  };

  const getFieldDetails = () => {
    return (
      <RecommendationInfoFieldInfo data-testid="rec-field-info">
        <img src={Field} alt="No img found" className="icon-size field-image" />
        {totalFields && totalFields > 1
          ? `${totalFields} ${t('fields')}`
          : `${totalFields} ${t('field')}`}{' '}
        <div className="white-dot" />
        {`${parseFloat(fieldSummaryArea).toFixed(2)} ${t('ha')}`}
        <img src={Separator} alt="No img found" className="separator-icon" />
        <img
          src={getCropIcon(recommendationCW?.crop_name.toLowerCase())}
          alt="No img found"
          className="icon-size crop-icon"
        />
        {recommendationCW?.crop_type !== CropConstants.BARLEY ? t(
          `${recommendationCW?.crop_type
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}`
        ) : t(CropConstants.HYBRID_BARLEY)}
      </RecommendationInfoFieldInfo>
    );
  };
  const loading = isNonDDG
    ? isRecommendationAPILoading || nonDDGUserDetailsLoading
    : isRecommendationAPILoading;

  return (
    <RecommendationInfoWrapper cropName={recommendationCW?.crop_name.toLowerCase() ?? ''}>
      {loading ? (
        SkeletonLoader
      ) : (
        <RecommendationInfoSection isMobile={isMobile}>
          <div className="recommendation-info-first-section">
            {isNonDDG ? getNonDDGUserDetails() : getDDGUserDetails()}
          </div>
          {!isMobile && <img src={TopBarPattern} alt="No img found" className="leaflet-image" />}
          {isMobile ? getFieldDetailsMobile() : getFieldDetails()}
          <RecommendationInfoReportOverview>
            {!isMobile && (
              <StyledHeaderText data-testid="seed-info-crop-wise">
                {t('Our Expert Seed Recommendation')}
              </StyledHeaderText>
            )}
            <StyledHeaderSmallText>
              <IconBox>
                <img src={Msg} alt="No img found" className="icon-size" />
              </IconBox>
              <div style={{ paddingLeft: '8px' }}>{t('Report overview')}</div>
            </StyledHeaderSmallText>
            <StyledHeaderVerySmallText>
              {t(`The Expert Seed Advice description ${countryRegion}`, {
                num: getTrialLocation(recommendationCW?.crop_name ?? '', countryRegion ?? ''),
              })}
            </StyledHeaderVerySmallText>
          </RecommendationInfoReportOverview>
        </RecommendationInfoSection>
      )}
    </RecommendationInfoWrapper>
  );
};

export default RecommendationInfo;
