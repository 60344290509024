import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledRow,
  StyledDivText,
  StyledDivValue,
  StyledModalView,
  StyledYearSection,
  StyledYearButton,
  StyledDivider,
} from './TrialingInformationModal.styles';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { useAppState } from 'context/AppState';
import { AgronomicInputs } from 'base/types/RecommendationCropwise';
import { useFlowActions } from 'context/actions/flowActions';
import { CropConstants } from 'utils/constants/Crop';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

interface TrialingInformationModalProps {
  info: MapboxGeoJSONFeature[];
  onClose: () => void;
  trialYears?: string[];
}

const TrialingInformationModal = (props: TrialingInformationModalProps) => {
  const { info, onClose, trialYears } = props;
  const { t } = useTranslation();
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    ModalAnimationDirections.Up
  );
  const { setIsModalToBeClosed } = useFlowActions();
  const {
    flow: { isModalToBeClosed },
    apiData: { recommendationCropwise },
  } = useAppState();
  const [agronomicInputs, setAgronomicInputs] = useState<AgronomicInputs>();
  const [selectedYear, setSelectedYear] = useState(trialYears?.[0] ?? '');

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const agroInput =
        recommendationCropwise.recommendations[0]?.multi_field?.config?.agronomicInputs;
      setAgronomicInputs(agroInput);
    }
  }, [recommendationCropwise]);

  useEffect(() => {
    if (isModalToBeClosed) {
      setIsModalToBeClosed({ isModalToBeClosed: false });
      setSlidingDirection(ModalAnimationDirections.Down);
    }
  }, [isModalToBeClosed, setIsModalToBeClosed]);

  const handleAnimationEnd = () => {
    if (slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    onClose();
  };

  const metricLabel = useMemo(() => {
    if (recommendationCropwise?.country === CropConstants.Brazil) {
      return '';
    }
    if (recommendationCropwise?.country === CropConstants.Bulgaria) {
      return 'kg/ha';
    } else {
      return agronomicInputs?.extraInfo?.yieldRangeUnit ?? '(-)';
    }
  }, [agronomicInputs?.extraInfo?.yieldRangeUnit, recommendationCropwise?.country]);

  const getTrialsForYear = (year: string) => {
    return info.filter((trial) => trial?.properties?.trialYear === parseInt(year));
  };

  const trialsForSelectedYear = getTrialsForYear(selectedYear);

  const trialMatch = /\(trial (\d+)\)/.exec(selectedYear);
  const trialNumber = trialMatch ? parseInt(trialMatch[1]) : 0;
  const index = trialNumber > 0 ? trialNumber - 1 : 0;

  const selectedTrial =
    trialsForSelectedYear[index]?.properties || trialsForSelectedYear[0]?.properties;

  return (
    <StyledModalView
      isViewFooter
      showHeaderRectangle
      onCancel={onClose}
      width={490}
      title={selectedTrial?.commercialName}
      content={
        <>
          <StyledDivText>{t('Select a period to view info: ')}</StyledDivText>
          <StyledYearSection>
            {trialYears?.map((year) => (
              <StyledYearButton
                key={year}
                onClick={() => setSelectedYear(year)}
                isSelected={year === selectedYear}
              >
                {year}
              </StyledYearButton>
            ))}
          </StyledYearSection>

          <StyledDivider />
          <StyledRow>
            <StyledDivText>{t('Trial Year')}</StyledDivText>
            <StyledDivValue>{selectedTrial?.trialYear}</StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>{t('Average yield results in the trial')}</StyledDivText>
            <StyledDivValue>
              {selectedTrial?.avgCheckYield} {metricLabel}
            </StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>
              {selectedTrial?.commercialName} {t('yield result')}
            </StyledDivText>
            <StyledDivValue>
              {selectedTrial?.avgEntryYield} {metricLabel}
            </StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>{t('Absolute yield difference')}</StyledDivText>
            <StyledDivValue>
              {selectedTrial?.yieldDiff} {metricLabel}
            </StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>{t('Relative yield difference')}</StyledDivText>
            <StyledDivValue>{selectedTrial?.yieldDiffPercentage}%</StyledDivValue>
          </StyledRow>
        </>
      }
      slideDirection={slidingDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default TrialingInformationModal;
