/* eslint-disable indent */
/* eslint-disable array-bracket-newline */
import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
  colors,
  Form,
  Typography,
  Switch,
  Select,
  Row,
} from 'syngenta-digital-cropwise-react-ui-kit';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import useScrollToBottom from 'hooks/useScrollDown';
import {
  isMobile as isMobileByUserAgent,
  isTablet as isTabletByUserAgent,
} from 'react-device-detect';

import DatePicker from 'components/DatePicker/DatePicker';
import { InfoBubbleProps } from 'components/InfoBubble';
import IconKeyboardArrowRight from 'components/Icons/IconKeyboardArrowRight';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useAppState, useAppDispatch } from 'context/AppState';
import { ActionTypes } from 'context/actions/ApiDataActions';
import { HyperLinkConstants } from 'utils/constants/HyperLinkConstants';
import CustomExpandIcon from 'components/CustomExpandIcon/CustomExpandIcon';

import {
  StyledParagraph,
  StyledLink,
  StyledText,
  StyledSelect,
  StyledFormItem,
  StyledSliderRange,
  SpanSliderRange,
  ContainerSpanSliderRange,
  StyledInfoBubble,
  StyledLightSkeleton,
  StyledSmallSkeleton,
  StyledBigSkeleton,
  StyledCollapse,
  StyledPanel,
  StyledRadio,
  StyledRadioGroup,
  StyledCheckboxGroup,
  StyledMultiSelect,
  StyledDivider,
  ColoredText,
  StyledRadioGroupForIrrigation,
  StyledFormRadioItem,
} from './AgronomicInputsSider.styles';
import { useFlowActions } from 'context/actions/flowActions';
import { Country, Crop, YieldRange } from 'base/types/Countries';
import {
  formatYieldRangeLabel,
  getYieldRangeMetricLabel,
  getYieldRangeOptions,
} from 'utils/yieldRange';
import { ProductSeed } from 'base/types/Products';
import { CropConstants, getHarvestDate, tppZoneInSouthAfrica } from 'utils/constants/Crop';
import { SegmentOptions } from 'utils/constants/AGInputOptionsConstants';
import { DDLOptions, AgronomicWeighting } from 'context/store/recommendationFormReducer';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { isDateBetweenMonthRange } from 'utils/dateValidation';
import { track } from '@amplitude/analytics-browser';
import disableSwipeBack from '../../utils/mobileDisableBackSwipe';
import AgronomicWeightingsDrawer from 'components/AgronomicWeightingsDrawer';

const { Text } = Typography;
const { Option } = Select;
interface AgronomicsInputsProps {
  showErrors?: boolean;
  handleOnCollapsibleClick?: () => void;
  collapsibleActiveKey: ['1'] | ['0'];
  isMobile?: boolean;
}
interface RadioMouseEventTarget extends EventTarget {
  value: string;
}

interface RadioMouseEvent extends React.MouseEvent<HTMLElement> {
  target: RadioMouseEventTarget;
}

const TIMEOUT_INFO_BUBBLE_TOOLTIP = 10000;
const infoBubbleDefaultProps = {
  placement: 'right',
  width: 15,
  height: 15,
  color: colors.neutral90,
  getPopupContainer: (triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement,
} as InfoBubbleProps;

const AgronomicsInputs = ({
  showErrors,
  handleOnCollapsibleClick,
  collapsibleActiveKey,
  isMobile,
}: AgronomicsInputsProps): JSX.Element => {
  const [t] = useTranslation();
  const {
    recommendationForm: {
      agronomicInputs: {
        plantingDate,
        harvestDate,
        yieldRangeLabel,
        areFieldsIrrigated,
        productMadurityRange: faoFromStore,
        herbicideTraitOptions,
        broomrapeOptions,
        isBroomrapeInfestation,
        selectedBroomrape,
        selectedSegment,
        rotationIntensity,
        tillagePractice,
        selectedHerbicideTrait,
        agProducts,
      },
      countryCode,
      selectedCrop,
      agronomicWeightings,
    },
    apiData: {
      attributes,
      countryList,
      productMaturityRange,
      productMaturityRangeLoading,
      productMaturityRangeError,
      productCatalog,
      tppData,
    },
    flow: { isLongTermMobileDesign },
  } = useAppState();
  const appDispatcher = useAppDispatch();
  const [metricLabel, setMetricLabel] = useState('');
  const [yieldRanges, setYieldRanges] = useState<YieldRange[]>([]);
  const [slideRangeHasChange, setSlideRangeHasChange] = useState(false);
  const [isAgWeightingsSectionCollapsed, setIsAgWeightingsSectionCollapsed] = useState(true);
  const { collapseRef, scrollToBottom } = useScrollToBottom();
  const {
    setHarvestDate,
    setYieldRangeId,
    setYieldRangeLabel,
    setAreFieldsIrrigated,
    setPlantingDate,
    setProductMadurityRange,
    setAGProducts,
    fillAgronomicWeightings,
    setRotationIntensity,
    setTillagePractice,
    setSelectedHerbicideTrait,
    setSelectedBroomrape,
    setIsBroomrapeInfestation,
    setSelectedSegment,
    setHerbicideTraitOptions,
    resetAGInputs,
    setAdjustedProductMaturityRange,
    setProductCountBasedOnUserSelectedFAO,
  } = useRecommendationFormActions();
  const { setShowAgronomicWeightings } = useFlowActions();
  const [pickerPlantingDate, setPickerPlantingDate] = useState<Moment>();
  const [defaultPlantingDate, setDefaultPlantingDate] = useState<Moment>();

  const [pickerHarvestDate, setPickerHarvestDate] = useState<Moment>();
  const [defaultHarvestDate, setDefaultHarvestDate] = useState<Moment>();
  const [localSelectedCrop, setLocalSelectedCrop] = useState('');
  const adjustAgronomicRef = useRef<HTMLDivElement | null>(null);
  const [isHistoricalYieldRangeTooltipVisible, setIsHistoricalYieldRangeTooltipVisible] =
    useState(false);
  const [isTargetedPlantingDateTooltipVisible, setIsTargetedPlantingDateTooltipVisible] =
    useState(false);
  const [isTargetedHarvestDateTooltipVisible, setIsTargetedHarvestDateTooltipVisible] =
    useState(false);
  const [isBroomrapeResistanceTooltipVisible, setIsBroomrapeResistanceTooltipVisible] =
    useState(false);
  const [isHerbicideTechnologyTooltipVisible, setIsHerbicideTechnologyTooltipVisible] =
    useState(false);

  const [infoBubbleTooltipTimeout, setInfoBubbleTooltipTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const BROOMRAPE = 'broomrape';
  const spatialClassAsPerTPP = tppData?.spatialClass;

  useEffect(() => {
    if (isMobile && selectedCrop?.name?.toLowerCase().includes('corn')) {
      const slider = document.getElementById('faoSlider');
      slider?.addEventListener('touchstart', (e) => disableSwipeBack(e), { passive: false });
      return () => {
        slider?.removeEventListener('touchstart', disableSwipeBack);
      };
    }
  }, [selectedCrop]);

  useEffect(() => {
    const crop = selectedCrop?.name ?? '';
    const selectedCountry = countryList?.find((item: Country) => item.shortCode === countryCode);
    const currentCrop = selectedCountry?.crops.find((item: Crop) => item.CropName === crop);
    const changeYieldRangeOptions =
      countryCode &&
      [CropConstants.BRAZIL_CODE, CropConstants.SOUTH_AFRICA_CODE].includes(countryCode);
    currentCrop &&
      setYieldRanges(
        changeYieldRangeOptions ? getYieldRangeByCountry(currentCrop) : currentCrop?.YieldRange
      );
    setTillagePractice({ tillagePractice: 'Conventional/Ploughing' });
    setRotationIntensity({ rotationIntensity: 'Extensive' });

    if (selectedCrop?.name !== localSelectedCrop) {
      // cleanup the controls if selected crop changes
      setLocalSelectedCrop(selectedCrop?.name ?? '');
      setMetricLabel('');
      setHarvestDate({ harvestDate: null });
      setPlantingDate({ plantingDate: null });
      setAreFieldsIrrigated({ areFieldsIrrigated: false });
      fillAgronomicWeightings({ agronomicWeighting: [] });
      resetAGInputs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryCode,
    countryList,
    selectedCrop,
    setHarvestDate,
    setPlantingDate,
    spatialClassAsPerTPP,
  ]);

  useEffect(() => {
    const currentDate = moment();
    const lastDateOfCurrentSeason = moment(`${currentDate.year()}-06-30`);
    const remainingDaysToFinishCurrentSeason = lastDateOfCurrentSeason.diff(currentDate, 'days');

    const setupDefaultPlantingDate = () => {
      let defaultPlantingDateValue;

      if (remainingDaysToFinishCurrentSeason >= 0) {
        defaultPlantingDateValue = moment(`01 Apr ${currentDate.year()}`);
      } else {
        defaultPlantingDateValue = moment(`01 Apr ${currentDate.year() + 1}`);
      }

      setPickerPlantingDate(defaultPlantingDateValue);
      setDefaultPlantingDate(defaultPlantingDateValue);
    };

    const setupHarvestDate = () => {
      let defaultHarvestDateValue;

      if (remainingDaysToFinishCurrentSeason >= 0) {
        defaultHarvestDateValue = moment(`${getHarvestDate(countryCode)} ${currentDate.year()}`);
      } else {
        defaultHarvestDateValue = moment(
          `${getHarvestDate(countryCode)} ${currentDate.year() + 1}`
        );
      }

      setPickerHarvestDate(defaultHarvestDateValue);
      setDefaultHarvestDate(defaultHarvestDateValue);
    };

    setupDefaultPlantingDate();
    setupHarvestDate();

    const defaultYieldRange = yieldRanges.find((item) => item.default === true);

    setYieldRangeId({ yieldRangeId: defaultYieldRange?.id ?? '' });
    setYieldRangeLabel({ yieldRangeLabel: defaultYieldRange?.label ?? '' });
    if (
      countryCode &&
      [CropConstants.SOUTH_AFRICA_CODE].includes(countryCode) &&
      !yieldRanges.length
    ) {
      setMetricLabel('t/ha');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, setProductMadurityRange, setYieldRangeId, setYieldRangeLabel, yieldRanges]);

  useEffect(() => {
    if (plantingDate) {
      setPlantingDate({ plantingDate: moment(plantingDate) });
    }
    if (harvestDate) {
      setHarvestDate({ harvestDate: moment(harvestDate) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHarvestDate, setPlantingDate, t]);

  const displayFAOInputs: boolean = useMemo(() => {
    return productMaturityRange && !productMaturityRangeLoading && !productMaturityRangeError;
  }, [productMaturityRange, productMaturityRangeLoading, productMaturityRangeError]);

  const getProductsWithinFAO = (fao: [number, number]) => {
    const [min, max] = fao || [0, 0];
    const filteredProductsBasedOnFAO = agProducts.filter(
      (product) => product.fao >= min && product.fao <= max
    );
    return filteredProductsBasedOnFAO;
  };

  const getYieldRangeByCountry = (currentCrop: Crop) => {
    switch (true) {
      case countryCode === CropConstants.BRAZIL_CODE:
        return getYieldRangeOptions();
      case countryCode === CropConstants.SOUTH_AFRICA_CODE: {
        const yieldRange = currentCrop?.YieldRange.filter(
          (yRange) => yRange?.spatialClass === (spatialClassAsPerTPP?.[0] ?? [])
        ).map((item) => {
          const { id, label } = item;
          return {
            label,
            id,
            default: item.default,
          };
        });
        return yieldRange;
      }
      default:
        return [];
    }
  };
  const canDisableCalendar = (current: any): boolean => {
    return isDateBetweenMonthRange(current, 8, 1);
  };

  const isBeforePlantingDate = (current: any): boolean => {
    if (plantingDate) {
      return current < plantingDate;
    } else {
      return false;
    }
  };

  const onChangePlantingDate = (plantingDate: any) => {
    if (plantingDate && harvestDate && harvestDate < plantingDate) {
      setHarvestDate({ harvestDate: null });
    }
    setPlantingDate({ plantingDate });
  };

  const onChangeRotation = (value: any) => {
    setRotationIntensity({ rotationIntensity: value });
  };

  const onChangeTillage = (value: any) => {
    setTillagePractice({ tillagePractice: value });
  };

  const checkForProductsWithFAO = async (range: [number, number]) => {
    if (productMaturityRange && productCatalog.length && agProducts.length) {
      let productsWithinFAO = getProductsWithinFAO(range);
      setAGProducts({ agProducts: productsWithinFAO });
      setSlideRangeHasChange(true);

      if (productsWithinFAO.length < 3) {
        const { minFAO: minFromApi, maxFAO: maxFromApi } = productMaturityRange;
        const [min, max] = range;
        let localMin = min;
        let localMax = max;
        setProductMadurityRange({ productMadurityRange: [localMin, localMax] });
        let counter = 1;
        let tenMultiples = 10;

        setProductCountBasedOnUserSelectedFAO({
          productCountBasedOnUserSelectedFAO: productsWithinFAO.length,
        });

        while (productsWithinFAO.length < 3 && counter < 20) {
          tenMultiples = 10 * counter;
          localMin = localMin - tenMultiples;
          localMax = localMax + tenMultiples;

          if (localMin < minFromApi) {
            localMin = minFromApi;
          }
          if (localMax > maxFromApi) {
            localMax = maxFromApi;
          }

          productsWithinFAO = getProductsWithinFAO([localMin, localMax]);
          counter += 1;
        }

        setAdjustedProductMaturityRange({ adjustedProductMaturityRange: [localMin, localMax] });
        setAGProducts({ agProducts: productsWithinFAO });
      } else {
        // reset values
        const [min, max] = range;
        setProductCountBasedOnUserSelectedFAO({ productCountBasedOnUserSelectedFAO: -1 });
        setAdjustedProductMaturityRange({ adjustedProductMaturityRange: [min, max] });
      }
    }
  };

  const handleSliderChange = (sliderValue: [number, number]) => {
    const [min, max] = faoFromStore || [0, 0];
    const { minFAO: minFromApi, maxFAO: maxFromApi } = productMaturityRange;
    const [localMin, localMax] = sliderValue;
    let range: [number, number] = [0, 0];

    // Logic to update range values with difference of 20, avoid overlapping
    if (localMax - localMin < 20 && localMax !== max) {
      const newMax = localMax + 20;
      range = newMax > maxFromApi ? [min, maxFromApi] : [min, max];
    } else if (localMax - localMin < 20 && localMin !== min) {
      const newMin = localMin - 20;
      range = newMin < minFromApi ? [minFromApi, max] : [min, max];
    } else {
      range = [localMin, localMax];
    }
    setProductMadurityRange({ productMadurityRange: range });

    // auto adjusted fao to get at least 3 products
    checkForProductsWithFAO(range);
  };

  const handleOpenAGWeightings = useCallback(() => {
    if (isLongTermMobileDesign) {
      if (isAgWeightingsSectionCollapsed) {
        track('expand AG weightings', {'AG weightings expanded': true});
      }
      setIsAgWeightingsSectionCollapsed(!isAgWeightingsSectionCollapsed);
      scrollToBottom();
    } else {
      setShowAgronomicWeightings({ show: true });
    }
  }, [isAgWeightingsSectionCollapsed, isLongTermMobileDesign, setShowAgronomicWeightings]);

  const handleInfoBubbleAction = useCallback(
    (open: boolean, setTooltipState: Function) => {
      if (isTabletByUserAgent || isMobileByUserAgent) {
        if (open) {
          setTooltipState(true);
          setInfoBubbleTooltipTimeout(
            setTimeout(() => setTooltipState(false), TIMEOUT_INFO_BUBBLE_TOOLTIP)
          );
        } else {
          setTooltipState(false);
          infoBubbleTooltipTimeout && clearTimeout(infoBubbleTooltipTimeout);
        }
      } else {
        setTooltipState(open);
      }
    },
    [infoBubbleTooltipTimeout]
  );

  const renderYieldRange = useCallback(() => {
    if (!yieldRanges.length) {
      return <></>;
    }
    return yieldRanges.map((item, index) => {
      const value = formatYieldRangeLabel(item.label);
      const metric = getYieldRangeMetricLabel(item.label);
      if (!metricLabel) {
        setMetricLabel(metric);
      }
      return (
        <Option key={`yieldRange-${value}`} value={index} data-testid="select-input-option">
          {t(value)}
        </Option>
      );
    });
  }, [yieldRanges, metricLabel, t]);
  const renderDDLValues = useCallback(
    (ddlOptions: any) =>
      ddlOptions.map((item: any) => {
        return (
          <Option key={`DDL-${item.label}`} value={item.label} data-testid="select-input-option">
            {t(item.label)}
          </Option>
        );
      }),
    [t]
  );

  const onChangeHT = (option: any) => {
    let updatedDDLOptions: DDLOptions[];
    if (option.length === 2) {
      const optionName = option.map((a: any) => a.value);
      updatedDDLOptions = herbicideTraitOptions.map((item) => {
        if (!optionName.includes(item.label)) {
          return { ...item, disabled: true };
        }
        return item;
      });
    } else {
      updatedDDLOptions = herbicideTraitOptions.map((item) => {
        return { ...item, disabled: false };
      });
    }
    setHerbicideTraitOptions({
      herbicideTraitOptions: updatedDDLOptions,
    });
    const selectedTraitOptions = option?.map((item: any) => {
      return {
        label: item.label,
        value: item.option,
      };
    });
    setSelectedHerbicideTrait({ selectedHerbicideTrait: selectedTraitOptions });
  };

  const getHTValue = () => {
    return selectedHerbicideTrait.map((item) => t(item.label));
  };

  const onSegmentChange = (list: CheckboxValueType[]) => {
    setSelectedSegment({ selectedSegment: list });
  };

  const getToolTipTextWithHyperLink = (labelName: string) => {
    const toolTipInformationText =
      labelName === BROOMRAPE
        ? `${t('Broomrape resistance technology tooltip information')}: `
        : `${t('Herbicide technology tooltip information')}: `;
    const { link: hyperLink = '', linkName: hyperLinkText = '' } =
      HyperLinkConstants?.find((item) => item.countryCode === countryCode) ?? {};

    return (
      <span>
        {toolTipInformationText}
        <a href={hyperLink} rel="noreferrer" target='_blank'>
          {t(hyperLinkText)}
        </a>
      </span>
    );
  };

  const handleFillAgronomicWeightings = useCallback(
    (agronomicWeighting: AgronomicWeighting[]) => {
      fillAgronomicWeightings({ agronomicWeighting: agronomicWeighting });
    },
    [fillAgronomicWeightings]
  );

  const getAGInputsForSunflower = () => {
    return (
      <>
        <StyledFormItem>
          <StyledText>{t('What is the intensity of your sunflower rotation?')}</StyledText>
          <StyledRadioGroup defaultValue={rotationIntensity} data-testid="crop-rotation">
            <StyledRadio
              value="Extensive"
              onClick={(e: RadioMouseEvent) => onChangeRotation(e.target.value)}
            >
              <span className="radioOptionLabel">{t('Extensive')}</span>
            </StyledRadio>
            <StyledRadio
              value="Intensive"
              onClick={(e: RadioMouseEvent) => onChangeRotation(e.target.value)}
            >
              <span className="radioOptionLabel">{t('Intensive')}</span>
            </StyledRadio>
          </StyledRadioGroup>
        </StyledFormItem>
        <StyledFormItem>
          <StyledText>{t('Which tillage practice do you apply?')}</StyledText>
          <StyledRadioGroup defaultValue={tillagePractice} data-testid="tillage-practice">
            <StyledRadio
              value="Conventional/Ploughing"
              onClick={(e: RadioMouseEvent) => onChangeTillage(e.target.value)}
            >
              {t('Conventional/Ploughing')}
            </StyledRadio>
            <StyledRadio
              value={'Minimal Tillage'}
              onClick={(e: RadioMouseEvent) => onChangeTillage(e.target.value)}
            >
              {t('Minimal Tillage')}
            </StyledRadio>
            <StyledRadio
              value="No Tillage"
              onClick={(e: RadioMouseEvent) => onChangeTillage(e.target.value)}
            >
              {t('No Tillage')}
            </StyledRadio>
          </StyledRadioGroup>
        </StyledFormItem>
        <StyledFormItem>
          <Row justify="space-between" align="middle" className="agronomic-input-label-row">
            <StyledText>
              {t('Which herbicide technology are you going to use?')}
              <Text type="secondary"> ({t('Optional')})</Text>
            </StyledText>
            <StyledInfoBubble
              title={getToolTipTextWithHyperLink('herbicide')}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isHerbicideTechnologyTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsHerbicideTechnologyTooltipVisible);
                if (open) {
                  track('user read herbicide info', { 'read herbicide info': true });
                }
              }}
              className="herbicide-technology-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <StyledMultiSelect
            mode="multiple"
            id="multiSelect"
            showSearch={false}
            style={{ width: '100%' }}
            placeholder={t('Select')}
            optionLabelProp="label"
            onChange={(e, option) => onChangeHT(option)}
            value={getHTValue()}
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
            data-testid="herbicide-traitoptions-select"
          >
            {herbicideTraitOptions.map((options) => (
              <Option
                key={options?.value}
                value={options?.label}
                label={options?.label}
                disabled={options?.disabled}
                option={options?.value}
                data-testid="herbicide-trait-options"
              >
                {options?.label}
              </Option>
            ))}
          </StyledMultiSelect>
        </StyledFormItem>
        <StyledFormItem>
          <StyledText>{t('Do you have broomrape infestation?')}</StyledText>
          <Switch
            data-testid="broomrape-infestation-switch"
            onChange={(isBroomrapeInfestation) => {
              setIsBroomrapeInfestation({ isBroomrapeInfestation });
              setSelectedBroomrape({ selectedBroomrape: [] });
            }}
            checked={isBroomrapeInfestation}
          />
        </StyledFormItem>
        <StyledFormItem>
          <Row justify="space-between" align="middle" className="agronomic-input-label-row">
            <StyledText>
              {t('What type of broomrape resistance technology do you need?')}
              {countryCode === CropConstants.ROMANIA_POSTAL_CODE && (
                <Text type="secondary"> ({t('Optional')})</Text>
              )}
            </StyledText>

            <StyledInfoBubble
              title={getToolTipTextWithHyperLink('broomrape')}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isBroomrapeResistanceTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsBroomrapeResistanceTooltipVisible);
                if (open) {
                  track('user read broomrape info', { 'read broomrape info': true });
                }
              }}
              className="broomrape-resistance-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <StyledSelect
            placeholder={t('Select')}
            disabled={!isBroomrapeInfestation}
            suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} color={colors.neutral60} />}
            onChange={(e) => setSelectedBroomrape({ selectedBroomrape: [e] })}
            data-testid="select-input-broomrape-infestation"
            value={selectedBroomrape.length > 0 ? selectedBroomrape : null}
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
          >
            {renderDDLValues(broomrapeOptions)}
          </StyledSelect>
        </StyledFormItem>
        <StyledFormItem>
          <Row justify="space-between" align="middle">
            <StyledText>{`${t('Historical yield range')} (${t(metricLabel)})`}</StyledText>
            <StyledInfoBubble
              title={t(
                'Over the past 3 - 5 years, what has been the average yield for your property?'
              )}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isHistoricalYieldRangeTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsHistoricalYieldRangeTooltipVisible);
              }}
              className="sunflower-historical-yield-range-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <StyledSelect
            placeholder={t('Select Yield Range')}
            suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} color={colors.neutral60} />}
            onChange={(yieldRangeIdx) => {
              setYieldRangeId({ yieldRangeId: yieldRanges[yieldRangeIdx].id });
              setYieldRangeLabel({ yieldRangeLabel: yieldRanges[yieldRangeIdx].label });
            }}
            data-testid="select-input-yield-range"
            value={t(formatYieldRangeLabel(yieldRangeLabel))}
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
          >
            {renderYieldRange()}
          </StyledSelect>
        </StyledFormItem>
        <StyledFormItem>
          <Row justify="space-between" align="middle">
            <StyledText>{t('Targeted planting date')}</StyledText>
            <StyledInfoBubble
              title={t('Based on your experience, approximately when do you plant the crop?')}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isTargetedPlantingDateTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsTargetedPlantingDateTooltipVisible);
              }}
              className="sunflower-targeted-planting-date-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <DatePicker
            disabledDate={canDisableCalendar}
            data-testid="planting-date-input"
            value={plantingDate}
            defaultValue={defaultPlantingDate}
            onChange={onChangePlantingDate}
            placeholder={t('Select date')}
            defaultPickerValue={pickerPlantingDate}
          />
        </StyledFormItem>
        <StyledFormItem>
          <Row justify="space-between" align="middle">
            <StyledText>
              {t('Targeted harvest date')} <Text type="secondary">({t('Optional')})</Text>
            </StyledText>
            <StyledInfoBubble
              title={t('Based on your experience, approximately when would you harvest the crop?')}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isTargetedHarvestDateTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsTargetedHarvestDateTooltipVisible);
              }}
              className="sunflower-targeted-harvest-date-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <DatePicker
            disabledDate={isBeforePlantingDate}
            data-testid="harvest-date-input"
            value={harvestDate}
            defaultValue={defaultHarvestDate}
            onChange={(harvestDate) => {
              setHarvestDate({ harvestDate });
            }}
            placeholder={t('Select date')}
            defaultPickerValue={pickerHarvestDate}
          />
        </StyledFormItem>
        <StyledFormItem>
          <StyledText>{t('Select all crop segment(s) that apply')}</StyledText>
          <StyledCheckboxGroup
            className="timelineStatusFilterCheckboxGroup"
            data-testid="crop-segments"
            options={SegmentOptions.map((item) => {
              return { ...item, label: t(item.label) };
            })}
            value={selectedSegment}
            onChange={(e: CheckboxValueType[]) => onSegmentChange(e)}
          />
        </StyledFormItem>
      </>
    );
  };

  const commonQnsForCornAndWheat = () => {
    return (
      <>
        <StyledFormItem>
          <Row justify="space-between" align="middle">
            <StyledText>{`${t('Historical yield range')} (${t(metricLabel)})`}</StyledText>
            <StyledInfoBubble
              title={t(
                'Over the past 3 - 5 years, what has been the average yield for your property?'
              )}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isHistoricalYieldRangeTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsHistoricalYieldRangeTooltipVisible);
              }}
              className="historical-yield-range-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <StyledSelect
            placeholder={t('Select Yield Range')}
            suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} color={colors.neutral60} />}
            onChange={(yieldRangeIdx) => {
              setYieldRangeId({ yieldRangeId: yieldRanges[yieldRangeIdx].id });
              setYieldRangeLabel({ yieldRangeLabel: yieldRanges[yieldRangeIdx].label });
            }}
            disabled={spatialClassAsPerTPP && spatialClassAsPerTPP[0] === tppZoneInSouthAfrica}
            data-testid="select-input"
            value={yieldRangeLabel && t(formatYieldRangeLabel(yieldRangeLabel))}
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
          >
            {renderYieldRange()}
          </StyledSelect>
        </StyledFormItem>

        <StyledFormItem>
          <Row justify="space-between" align="middle">
            <StyledText>{t('Targeted planting date')}</StyledText>
            <StyledInfoBubble
              title={t('Based on your experience, approximately when do you plant the crop?')}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isTargetedPlantingDateTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsTargetedPlantingDateTooltipVisible);
              }}
              className="targeted-planting-date-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <DatePicker
            disabledDate={canDisableCalendar}
            data-testid="planting-date-input"
            value={plantingDate}
            defaultValue={defaultPlantingDate}
            onChange={onChangePlantingDate}
            placeholder={t('Select date')}
            defaultPickerValue={pickerPlantingDate}
          />
        </StyledFormItem>

        <StyledFormItem>
          <Row justify="space-between" align="middle">
            <StyledText>
              {t('Targeted harvest date')}
              <Text type="secondary"> ({t('Optional')})</Text>
            </StyledText>
            <StyledInfoBubble
              title={t('Based on your experience, approximately when would you harvest the crop?')}
              trigger={isTabletByUserAgent || isMobileByUserAgent ? ['click'] : ['hover']}
              open={isTargetedHarvestDateTooltipVisible}
              onOpenChange={(open) => {
                handleInfoBubbleAction(open, setIsTargetedHarvestDateTooltipVisible);
              }}
              className="targeted-harvest-date-tooltip"
              {...infoBubbleDefaultProps}
            />
          </Row>
          <DatePicker
            disabledDate={isBeforePlantingDate}
            data-testid="harvest-date-input"
            value={harvestDate}
            defaultValue={defaultHarvestDate}
            onChange={(harvestDate) => {
              setHarvestDate({ harvestDate });
            }}
            placeholder={t('Select date')}
            defaultPickerValue={pickerHarvestDate}
          />
        </StyledFormItem>
      </>
    );
  };
  const getAGInputsForCornAndBarley = (isBarley: boolean) => {
    return (
      <>
      {!isBarley && (
        <div data-testid="faoSliderCorn">
        <StyledFormItem>
          <StyledText>{t('Product maturity range (FAO)')}</StyledText>
          <StyledSliderRange
            id="faoSlider"
            range
            enableGlowHighlight={slideRangeHasChange}
            defaultValue={
              faoFromStore?.length
                ? [faoFromStore[0], faoFromStore[1]]
                : productMaturityRange?.minFAO && productMaturityRange.maxFAO
                  ? [productMaturityRange.minFAO, productMaturityRange.maxFAO]
                  : ([0, 0] as [number, number])
            }
            value={faoFromStore?.length ? [faoFromStore[0], faoFromStore[1]] : [0, 0]}
            min={productMaturityRange.minFAO}
            max={productMaturityRange.maxFAO}
            step={productMaturityRange.maxFAO - productMaturityRange.minFAO < 10 ? 1 : 10}
            onChange={handleSliderChange}
            onAfterChange={handleSliderChange}
            disabled={productMaturityRange.minFAO === 0 && productMaturityRange.maxFAO === 0}
          />
          <ContainerSpanSliderRange>
            <SpanSliderRange>{productMaturityRange.minFAO}</SpanSliderRange>
            <SpanSliderRange>{productMaturityRange.maxFAO}</SpanSliderRange>
          </ContainerSpanSliderRange>
        </StyledFormItem>
      </div>
      )}
        {commonQnsForCornAndWheat()}
        {!isBarley && (
          <StyledFormItem>
          <StyledText>
            {t('Are your fields irrigated?')}
            <Switch
              data-testid="are-fields-irrigated-switch"
              onChange={(areFieldsIrrigated) => {
                setAreFieldsIrrigated({ areFieldsIrrigated });
              }}
              checked={areFieldsIrrigated}
            />
          </StyledText>
        </StyledFormItem>
        )}
        {showErrors && (
          <Text type="danger" strong data-testid="error-message">
            {t('Please complete the required fields continue')}
          </Text>
        )}
      </>
    );
  };
  const handleIsIrrigatedRadioBtn = (val: string | boolean) => {
    let updatedTppSpatialClass = tppZoneInSouthAfrica;
    if (val === true) {
      setAreFieldsIrrigated({ areFieldsIrrigated: true });
      updatedTppSpatialClass = updatedTppSpatialClass.concat('Irrigated');
    } else {
      setAreFieldsIrrigated({ areFieldsIrrigated: false });
      updatedTppSpatialClass = updatedTppSpatialClass.concat('Rainfed');
    }
    const updatedTPPForFields = {
      ...tppData,
      spatialClass: [updatedTppSpatialClass],
    };
    appDispatcher({
      type: ActionTypes.setTPPForFields,
      payload: { data: updatedTPPForFields },
    });
  };
  const getAGInputsForWheat = () => {
    return (
      <>
        {spatialClassAsPerTPP && spatialClassAsPerTPP[0]?.includes(tppZoneInSouthAfrica) && (
          <StyledFormRadioItem>
            <StyledText>
              {t('Are your fields irrigated?')}
              <StyledRadioGroupForIrrigation
                defaultValue={null}
                data-testid="are-fields-irrigated-radio"
                onChange={(e: RadioMouseEvent) => handleIsIrrigatedRadioBtn(e.target.value)}
              >
                <StyledRadio value={true}>
                  <span className="radioOptionLabel">{t('Yes')}</span>
                </StyledRadio>
                <StyledRadio value={false}>
                  <span className="radioOptionLabel">{t('No')}</span>
                </StyledRadio>
              </StyledRadioGroupForIrrigation>
            </StyledText>
          </StyledFormRadioItem>
        )}
        {commonQnsForCornAndWheat()}
        {showErrors && (
          <Text type="danger" strong data-testid="error-message">
            {t('Please complete the required fields continue')}
          </Text>
        )}
      </>
    );
  };

  const getAGFormForSelectedCrop: any = () => {
    if (selectedCrop) {
      if (selectedCrop?.name?.toLowerCase().includes('corn')) {
        return getAGInputsForCornAndBarley(false);
      } else if (selectedCrop?.name?.toLowerCase().includes('sunflower')) {
        return getAGInputsForSunflower();
      } else if (selectedCrop?.name?.toLowerCase().includes('wheat')) {
        return getAGInputsForWheat();
      } else if (selectedCrop?.name?.toLowerCase().includes('barley')) {
        return getAGInputsForCornAndBarley(true);
      }
    }
  };

  const getAGWeightingsSection: any = () => {
    if (!isLongTermMobileDesign) {
      return (
        <StyledLink
          onClick={handleOpenAGWeightings}
          style={{ marginBottom: undefined }}
          data-testid="ag-weightings-drawer-link"
        >
          <div>
            {`${t('Adjust Agronomic Weightings')}`}
            <ColoredText> ({t('Optional')})</ColoredText>
          </div>
          <div>
            <IconKeyboardArrowRight color={colors.blue60} width={6} height={9} />
          </div>
        </StyledLink>
      );
    } else {
      return (
        <StyledCollapse
          onChange={handleOpenAGWeightings}
          defaultActiveKey={['0']}
          expandIconPosition="end"
          activeKey={isAgWeightingsSectionCollapsed ? ['0'] : ['1']}
          expandIcon={CustomExpandIcon}
          isLongTermMobileDesign={isLongTermMobileDesign}
        >
          <div ref={collapseRef} />
          <StyledPanel
            header={
              <StyledLink
                onClick={handleOpenAGWeightings}
                style={{ marginBottom: undefined }}
                data-testid="ag-weightings-long-term-link"
              >
                <div>
                  {`${t('Adjust Agronomic Weightings')}`}
                  <ColoredText> ({t('Optional')})</ColoredText>
                </div>
              </StyledLink>
            }
            key={'1'}
          >
            <AgronomicWeightingsDrawer
              showAgronomicWeightings={true}
              agronomicWeightings={agronomicWeightings}
              attributes={attributes}
              handleFillAgronomicWeightings={handleFillAgronomicWeightings}
            />
          </StyledPanel>
        </StyledCollapse>
      );
    }
  };

  return (
    <>
      <div data-testid="agronomics-inputs">
        <StyledCollapse
          onChange={handleOnCollapsibleClick}
          defaultActiveKey={['1']}
          expandIconPosition="end"
          activeKey={collapsibleActiveKey}
          expandIcon={CustomExpandIcon}
        >
          <StyledPanel
            header={`${t('Step {{stepNumber}}', { stepNumber: 3 })}: ${t('Agronomics Inputs')}`}
            key={'1'}
          >
            {!displayFAOInputs ? (
              <>
                <Row style={{ width: '325px', marginTop: '10px' }}>
                  <StyledLightSkeleton active paragraph={{ rows: 1 }} />
                </Row>
                <Row>
                  <StyledSmallSkeleton active paragraph={{ rows: 0 }} />
                </Row>
                <Row>
                  <StyledBigSkeleton active paragraph={{ rows: 0 }} />
                </Row>
                <Row>
                  <StyledBigSkeleton active paragraph={{ rows: 0 }} />
                </Row>
                <Row>
                  <StyledBigSkeleton active paragraph={{ rows: 0 }} />
                </Row>
                <Row>
                  <StyledBigSkeleton active paragraph={{ rows: 0 }} />
                </Row>
              </>
            ) : (
              <>
                {selectedCrop?.name?.toLowerCase().includes('sunflower') ? (
                  <StyledParagraph>
                    {t('Agronomic Inputs Description For Sunflower')}
                  </StyledParagraph>
                ) : (
                  <StyledParagraph>{t('Agronomic Inputs Description')}</StyledParagraph>
                )}
                <Form>{getAGFormForSelectedCrop()}</Form>
                <Row justify="start">
                  <StyledDivider />
                  {getAGWeightingsSection()}
                  <StyledDivider />
                </Row>
              </>
            )}
          </StyledPanel>
        </StyledCollapse>
      </div>
      <div ref={adjustAgronomicRef}> </div>
    </>
  );
};

export default AgronomicsInputs;
